<template>
  <b-overlay :show="false" rounded="sm" variant="white">
    <b-card id="summary-card">
      <!-- DETAILS -->
      <b-row>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Nom complet</h6>
          <div class="d-flex" v-if="editMode">
            <b-form-input v-model="editedDetails.firstName" placeholder="Nom" />
            <b-form-input
              v-model="editedDetails.lastName"
              placeholder="Llinatges"
            />
          </div>
          <b-card-text v-else>{{ fullName }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Identificació</h6>
          <div class="d-flex" v-if="editMode">
            <b-form-radio-group
              v-model="editedDetails.idType"
              :options="idTypeOptions"
              text-field="label"
              class="d-flex align-items-center"
            />
            <b-form-input
              v-model="editedDetails.idNumber"
              placeholder="Nº de document"
              class="mr-1"
            />
          </div>
          <b-card-text v-else>{{ idNumber }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Telèfon</h6>
          <b-form-input v-if="editMode" v-model="editedDetails.phone" />
          <b-card-text v-else>{{ phone }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Telèfon secundari</h6>
          <b-form-input
            v-if="editMode"
            v-model="editedDetails.secondaryPhone"
          />
          <b-card-text v-else>{{ secondaryPhone }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Correu electrònic</h6>
          <b-form-input v-if="editMode" v-model="editedDetails.email" />
          <b-card-text v-else>{{ email }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Correu electrònic secundari</h6>
          <b-form-input
            v-if="editMode"
            v-model="editedDetails.secondaryEmail"
          />
          <b-card-text v-else>{{ secondaryEmail }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">Adreça</h6>
          <b-card-text>{{ fullAddress }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h6 class="mb-25">
            Enllaç de registre
            <small class="ml-50">
              <b-link @click="$copyText(registerLink)"> Copia </b-link>
            </small>
          </h6>
          <b-card-text>{{ registerLink }}</b-card-text>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="onSaveButtonClicked">
            Guarda
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="onEditButtonClicked">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardText,
  BRow,
  BCol,
  BLink,
  BButton,
  BFormInput,
  BFormRadioGroup,
} from "bootstrap-vue";
import { AGENCY_OWNERS_INTRANET_REGISTER } from "@appConfig";
import { getFullAddress, notifyError, notifySuccess } from "@/utils/methods";
import { idTypeOptions } from "@/utils/select-options";

export default {
  components: {
    BOverlay,
    BCard,
    BCardText,
    BRow,
    BCol,
    BLink,
    BButton,
    BFormInput,
    BFormRadioGroup,
  },
  data() {
    return {
      editMode: false,
      editedDetails: {
        firstName: null,
        lastName: null,
        idType: null,
        idNumber: null,
        phone: null,
        secondaryPhone: null,
        email: null,
        secondaryEmail: null,
      },
      idTypeOptions,
    };
  },
  computed: {
    owner() {
      return this.$store.getters["owner/owner"];
    },
    fullName() {
      return this.owner?.fullName || this.$t("No disponible");
    },
    idNumber() {
      return this.owner?.idNumber || this.$t("No disponible");
    },
    phone() {
      return this.owner?.phone || this.$t("No disponible");
    },
    secondaryPhone() {
      return this.owner?.secondaryPhone || this.$t("No disponible");
    },
    email() {
      return this.owner?.email || this.$t("No disponible");
    },
    secondaryEmail() {
      return this.owner?.secondaryEmail || this.$t("No disponible");
    },
    fullAddress() {
      return (
        getFullAddress(this.owner?.location, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    registerLink() {
      if (!this.owner) return null;
      const ownerLocale = this.owner?.language || "en";
      return `${AGENCY_OWNERS_INTRANET_REGISTER}/${this.owner.uuid}?l=${ownerLocale}`;
    },
  },
  methods: {
    initInputs() {
      this.editedDetails = {
        firstName: this.owner.firstName || null,
        lastName: this.owner.lastName || null,
        idType: this.owner.idType || null,
        idNumber: this.owner.idNumber || null,
        phone: this.owner.phone || null,
        secondaryPhone: this.owner.secondaryPhone || null,
        email: this.owner.email || null,
        secondaryEmail: this.owner.secondaryEmail || null,
      };
    },
    onEditButtonClicked() {
      this.editMode = true;
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch(`owner/updateOwner`, {
          uuid: this.owner?.uuid,
          ...this.editedDetails,
        })
        .then(() => {
          notifySuccess(
            "Propietari actualitzat",
            "El propietari s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Detalls no actualitzats",
            "El propietari no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
  watch: {
    editMode(value) {
      if (value) {
        this.initInputs();
      }
    },
  },
};
</script>
