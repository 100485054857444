<template>
  <div class="bookings-table">
    <!-- NEW BOOKING BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col v-if="showButton" cols="3" md="auto" class="mb-1 mb-md-0">
        <b-button
          variant="primary"
          block
          class="d-flex justify-content-center"
          @click.prevent="onButtonClicked"
        >
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Afegeix reserva") }}</span>
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="bookings"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: date -->
          <template #cell(date)="data">
            {{ bookingDate(data.item.date) }}
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-status-badge :status="data.item.status" />
          </template>

          <!-- Column: checkin -->
          <template #cell(checkin)="data">
            {{ bookingFormattedDate(data.item.checkin) }}
          </template>

          <!-- Column: checkout -->
          <template #cell(checkout)="data">
            {{ bookingFormattedDate(data.item.checkout) }}
          </template>

          <!-- Column: client -->
          <template #cell(client)="data">
            <b-client :uuid="data.item.clientUuid" :name="data.value" />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="bookings.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import {
  getBookingStatusName,
  getBookingStatusColor,
  getBookingSourceImage,
  getOtaName,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
    BStatusBadge,
    BBookingLocalizator,
    BClient,
    BAccommodation,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: true,
      perPage: 10,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "date", label: this.$t("Data"), sortable: true },
        { key: "localizator", label: this.$t("Nº reserva"), sortable: true },
        { key: "accommodation", label: this.$t("Allotjament"), sortable: true },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
        { key: "client", label: this.$t("Client"), sortable: true },
        { key: "status", label: this.$t("Estat"), sortable: true },
      ],
    };
  },
  watch: {
    bookings(bookings) {
      this.totalRows = bookings.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.bookings.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onButtonClicked() {
      this.$emit("button-click");
    },
    bookingStatusName(status) {
      return getBookingStatusName(status);
    },
    bookingStatusColor(status) {
      return getBookingStatusColor(status);
    },
    bookingSourceName(source) {
      return getOtaName(source);
    },
    bookingSourceImage(source) {
      return getBookingSourceImage(source);
    },
    bookingDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    bookingFormattedDate(date) {
      return formatDateStringToDate(date);
    },
  },
};
</script>

<style lang="scss">
.bookings-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
