<template>
  <div v-if="owner">
    <b-tabs no-fade>
      <b-tab active>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="InfoIcon" />
          <span class="d-none d-lg-block">{{ $t("Informació") }}</span>
        </template>

        <b-row>
          <b-col cols="12">
            <summary-card />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="BookIcon" />
          <span class="d-none d-md-block">{{ $t("Reserves") }}</span>
        </template>

        <bookings-card />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { notifyError } from "@/utils/methods";
import SummaryCard from "@/views/owners/owner/components/SummaryCard.vue";
import BookingsCard from "@/views/owners/owner/components/BookingsCard.vue";
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    SummaryCard,
    BookingsCard,
  },
  computed: {
    loadingOwner() {
      return this.$store.getters["owner/loadingOwner"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
  },
  watch: {
    owner(owner) {
      this.setPageTitle(owner);
    },
    "$route.params.ownerUuid"() {
      this.fetchOwner();
    },
  },
  mounted() {
    if (!this.loadingOwner) this.fetchOwner();
    this.setPageTitle(this.owner);
  },
  beforeDestroy() {
    this.$store.dispatch("owner/reset");
    this.setPageTitle(null);
  },
  methods: {
    fetchOwner() {
      this.$store.dispatch("app/setLoading", true);
      const { ownerUuid } = this.$route.params;

      this.$store
        .dispatch("owner/fetchOwner", ownerUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchOwners.title"),
            this.$t("errors.fetchOwners.description")
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    setPageTitle(owner) {
      this.$store.dispatch("app/setPageTitle", owner?.fullName || null);
      // this.$store.dispatch(
      //   "app/setPageSubtitle",
      //   owner?.accommodation?.name || null
      // );
    },
  },
};
</script>
